import * as React from "react";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Sheet, SheetContent, SheetTrigger } from "@/components/ui/sheet";
import type { MainNavItem, SidebarNavItem } from "@/types";
import { Menu } from "lucide-react";

interface SheetMobileProps {
  mainNavItems?: MainNavItem[];
  sidebarNavItems?: SidebarNavItem[];
}

export function SheetMobileNav({
  mainNavItems,
  sidebarNavItems,
}: SheetMobileProps) {
  const [open, setOpen] = React.useState(false);

  const mergedMainNavItems = mainNavItems?.filter(
    (item, index, self) =>
      index ===
      self.findIndex((t) => t.href === item.href && t.title === item.title)
  );

  // [
  //     ...marketingConfig.mainNav,
  //     ...docsConfig.mainNav,
  //   ]

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <button
          type="button"
          className="hs-collapse-toggle focus:ring-secondary inline-flex items-center justify-center gap-2 rounded-md border bg-white p-2 align-middle text-sm font-medium text-gray-700 transition-all hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white dark:border-gray-700 dark:bg-slate-900 dark:text-gray-400 dark:hover:bg-slate-800 dark:hover:text-white dark:focus:ring-offset-gray-800"
          aria-label="Toggle navigation"
        >
          <Menu className={" h-4 w-4 "} />
          <span className="sr-only">Toggle Menu</span>
        </button>
      </SheetTrigger>
      <SheetContent side="right" className="pr-0">
        <a href="/" className="flex items-center">
          <img
            width={200}
            height={200}
            loading="eager"
            className="w-8 sm:w-9 "
            src="/logo.png"
            alt="Logo"
          />
          <h1 className="font-fredoka text-xl ml-2">Ekonomi.FYI</h1>
        </a>
        <ScrollArea className="my-4 h-[calc(100vh-8rem)] pb-10 pl-3">
          <div className="mt-2 mb-20">
            {mainNavItems?.length ? (
              <div className="flex flex-col space-y-3">
                {mergedMainNavItems?.map(
                  (item) =>
                    item.href && (
                      <a
                        key={item.href}
                        href={item.href}
                        className="text-muted-foreground hover:text-secondary"
                        onClick={() =>
                          item.href.startsWith("/#")
                            ? setOpen(false)
                            : undefined
                        }
                      >
                        {item.title}
                      </a>
                    )
                )}
              </div>
            ) : null}

            {sidebarNavItems?.length ? (
              <div className="flex flex-col space-y-2">
                {sidebarNavItems.map((item, index) => {
                  const activeItems = item?.items?.filter(
                    (subItem) => !subItem.disabled
                  );

                  if (!activeItems || activeItems.length === 0) return null;

                  return (
                    <div key={index} className="flex flex-col space-y-3 pt-6">
                      <h4 className="font-medium">{item.title}</h4>
                      {activeItems.map((subItem, idx) => (
                        <React.Fragment key={subItem.href + idx}>
                          {subItem.href ? (
                            <a
                              href={subItem.href}
                              target={subItem?.external ? "_blank" : undefined}
                              className="text-muted-foreground hover:text-secondary"
                            >
                              {subItem.title}
                            </a>
                          ) : (
                            subItem.title
                          )}
                        </React.Fragment>
                      ))}
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
}
